import React from 'react'
import PropTypes from 'prop-types';
import { connectComponent } from '../store'
import { Link } from 'react-router-dom'

function MenuItem(props) {

    const language = props.language.language ? props.language.language : 'en';

    return (
        Object.keys(props.menus.entries).map(id => {

            const menu = props.menus.entries[id];
            const lang = menu.fields.lang;

            const get = function(obj, key) {
                return key.split(".").reduce(function(o, x) {
                    return (typeof o == "undefined" || o === null) ? o : o[x];
                }, obj);
            };

            const logo = get(menu, "fields.logo.fields.file.url");

            if (logo && lang === language) {
                return(
                    <li key={id}>
                        <img style={{marginTop: '0.5em'}} height={34} width={34} src={logo + "?fm=webp&h=80"} alt={'jussivesa.com logo image'} />
                        <Link to={menu.fields.url} >
                            <p className="c-d-2 line-animation">{menu.fields.name}</p>
                        </Link>
                    </li>
                );
            } else if (lang === language) {
                return(
                    <li key={id}>
                        <Link to={menu.fields.url} >
                            <p className="c-d-2 line-animation">{menu.fields.name}</p>
                        </Link>
                    </li>
                );
            }
        })
    );
}

MenuItem.propTypes = {
    menus: PropTypes.object,
    language: PropTypes.object
};

export default connectComponent(MenuItem)
