import cache from './cache';
import { referenceTypeId } from '../../contentful-config'

export function loadReferences (contentTypeId) {
  return cache.getCachedEntries({
      content_type: contentTypeId,
      order: '-fields.monthOfDevelopment' // '-sys.createdAt'
  }).then(payload => {
      return payload.items;
  });
}

export function loadReference (id) {
  return cache.getCachedEntries({
      'sys.id': id
  }).then(payload => {
      return payload.items[0];
  });
}

export function loadReferenceWithSlug (slug) {
  return cache.getCachedEntries({
      content_type: referenceTypeId,
      fields: {
        slug: slug
      }
  }).then(payload => {
      return payload.items[0];
  });
}
