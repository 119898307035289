import * as galleryService from '../services/galleryStore'
import * as postService from '../services/postStore'
import * as referenceService from '../services/referenceStore'
import * as skillService from '../services/skillStore'
import * as headerService from '../services/headerStore'
import * as footerService from '../services/footerStore'
import * as menuService from '../services/menuStore'
import * as authorService from '../services/authorStore'
import * as sectionService from '../services/sectionStore'
import * as dataJsonService from '../services/dataJsonStore'
import * as notificationService from '../services/notificationStore'
import * as languageService from '../services/languageStore'

export function setAppClientState(appClientState) {
    return {
        type: 'SET_APP_CLIENT_STATE',
        appClientState
    }
}

export function loadGalleries({contentTypeId}) {
    return {
        type: 'LOAD_GALLERIES',
        payload: galleryService.loadGalleries(contentTypeId)
    }
}

export function loadGallery(id) {
    return {
        type: 'LOAD_GALLERY',
        payload: galleryService.loadGallery(id),
        meta: {
            id
        }
    }
}

export function loadPosts({contentTypeId}) {
    return {
        type: 'LOAD_POSTS',
        payload: postService.loadPosts(contentTypeId)
    }
}

export function loadPost(id) {
    return {
        type: 'LOAD_A_POST',
        payload: postService.loadPost(id),
        meta: {
            id
        }
    }
}

export function loadPostWithSlug(id) {
    return {
        type: 'LOAD_A_POST',
        payload: postService.loadPostWithSlug(id),
        meta: {
            id
        }
    }
}

export function loadReferences({contentTypeId}) {
    return {
        type: 'LOAD_REFERENCES',
        payload: referenceService.loadReferences(contentTypeId)
    }
}

export function loadReference(id) {
    return {
        type: 'LOAD_A_REFERENCE',
        payload: referenceService.loadReference(id),
        meta: {
            id
        }
    }
}

export function loadReferenceWithSlug(id) {
    return {
        type: 'LOAD_A_REFERENCE',
        payload: referenceService.loadReferenceWithSlug(id),
        meta: {
            id
        }
    }
}

export function loadSkills({contentTypeId}) {
    return {
        type: 'LOAD_SKILLS',
        payload: skillService.loadSkills(contentTypeId)
    }
}

export function loadSkill(id) {
    return {
        type: 'LOAD_A_SKILL',
        payload: skillService.loadSkill(id),
        meta: {
            id
        }
    }
}

export function loadHeaders({contentTypeId}) {
    return {
        type: 'LOAD_HEADERS',
        payload: headerService.loadHeaders(contentTypeId)
    }
}

export function loadHeader(id) {
    return {
        type: 'LOAD_A_HEADER',
        payload: headerService.loadHeader(id),
        meta: {
            id
        }
    }
}

export function loadFooters({contentTypeId}) {
    return {
        type: 'LOAD_FOOTERS',
        payload: footerService.loadFooters(contentTypeId)
    }
}

export function loadFooter(id) {
    return {
        type: 'LOAD_A_FOOTER',
        payload: footerService.loadFooter(id),
        meta: {
            id
        }
    }
}

export function loadMenus({contentTypeId}) {
    return {
        type: 'LOAD_MENUS',
        payload: menuService.loadMenus(contentTypeId)
    }
}

export function loadMenu(id) {
    return {
        type: 'LOAD_A_MENU',
        payload: menuService.loadMenu(id),
        meta: {
            id
        }
    }
}

export function loadAuthors({contentTypeId}) {
    return {
        type: 'LOAD_AUTHORS',
        payload: authorService.loadAuthors(contentTypeId)
    }
}

/* NOTICE! Only plural */
export function loadSections({contentTypeId}) {
    return {
        type: 'LOAD_SECTIONS',
        payload: sectionService.loadSections(contentTypeId)
    }
}

/* NOTICE! Only plural */
export function loadNotifications({contentTypeId}) {
    return {
        type: 'LOAD_NOTIFICATIONS',
        payload: notificationService.loadNotifications(contentTypeId)
    }
}

/* NOTICE! Only plural */
export function loadDataJson({contentTypeId}) {
    return {
        type: 'LOAD_DATA_JSON',
        payload: dataJsonService.loadDataJson(contentTypeId)
    }
}

export function switchLanguage(language) {
    return {
        type: 'SWITCH_LANGUAGE',
        language: language,
        payload: languageService.switchLanguage(language)
    }
}

export function initLanguage(initialLanguage) {
    return {
        type: 'INIT_LANGUAGE',
        language: initialLanguage,
        payload: languageService.initLanguage(initialLanguage)
    }
}