function toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
}
var urlPathToPrettyText = function(path) {
    if (path.indexOf('-') !== 0) {
        var tmp = path.charAt(0).toUpperCase() + path.slice(1);
        if (tmp.indexOf('-') !== 0) {
            var tmp2 = tmp.replace('-', ' ');
            tmp2 = toTitleCase(tmp2);
            if (tmp2.indexOf('-') !== 0) {
                var tmp3 = tmp2.replace('-', ' ');
                tmp3 = toTitleCase(tmp3);
                if (tmp3.indexOf('-') !== 0) {
                    var tmp4 = tmp3.replace('-', ' ');
                    tmp4 = toTitleCase(tmp4);
                    return tmp4
                } else { return tmp3 }
            } else {
                return tmp2
            }
        } else { return tmp }
    } else {
        return path
    }
};

const _urlPathToPrettyText = urlPathToPrettyText;
export { _urlPathToPrettyText as urlPathToPrettyText };