// Load our own defaults
import defaults from './env/defaults';

// Load local config
import localConfigFile from './env/local.config';
var localConfig = {};
if (process.env.NODE_ENV !== 'production') {
    localConfig = localConfigFile;
}

// merge the config files
// localConfig will override defaults
const config = Object.assign({}, defaults, localConfig);

export default config;
