import cache from './cache';

export function loadGalleries (contentTypeId) {
  return cache.getCachedEntries({
      content_type: contentTypeId
  }).then(payload => {
      return payload.items;
  });
}

export function loadGallery (id) {
  return cache.getCachedEntries({
      'sys.id': id
  }).then(payload => {
      return payload.items[0];
  });
}
