import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules'
import styles from './App.css'
import { connectComponent } from '../store'
import { InitClient } from '../services/contentfulClient'
import DocumentMeta from 'react-document-meta';

import ContentfulError from '../components/ContentfulError'
import Navigation from '../components/Navigation'
import Header from '../components/Header'
import MainFooter from '../components/MainFooter'
import LoadingAnimation from '../components/LoadingAnimation'

import { Fade } from 'react-awesome-reveal';

/**
 * https://www.debugbear.com/blog/bundle-splitting-components-with-webpack-and-react
 *
 * Lazy Load components and use React.Suspense
 */
const ReferenceList = React.lazy(() => import("../components/ReferenceList"));
const Profile = React.lazy(() => import("../components/Profile"));
const SocialFeed = React.lazy(() => import("../components/SocialFeed"));
const Now = React.lazy(() => import("../components/Now"));

import UnderMaintenance from '../components/UnderMaintenance'

import config from '../../config/index';
import { addToast } from '../helpers/notification';

function App(props) {

  const [preferredLanguage, setPreferredLanguage] = useState(localStorage.getItem('prefLang') || 'fi');  // fi is default

  useEffect(() => {

    // Set storage if not set
    if (!localStorage.getItem('prefLang')) {
      localStorage.setItem('prefLang', 'fi');
      setPreferredLanguage('fi');
    }

    const { spaceId, deliveryAccessToken, notificationsTypeId, postTypeId, sectionTypeId, referenceTypeId } = props.app;

    const delay = (ms) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    const loadData = () => {
      if (window.location.pathname === '/') {
        props.loadSections({ contentTypeId: sectionTypeId});
        // Non-critical data
        delay(1500).then(() => {
          props.loadReferences({ contentTypeId: referenceTypeId });
        }).then(() => {
          delay(1000).then(() => {
            // Even as the index does not need posts, we load them, as when route changes, props are not updated so we need the data before hand.
            // Page reload e.g. on /blogs will load data in the else block below.
            props.loadNotifications({ contentTypeId: notificationsTypeId });
            props.loadPosts({ contentTypeId: postTypeId});
          });
        });
      } else {
        // Always need general stuff
        props.loadSections({ contentTypeId: sectionTypeId});

        if (window.location.pathname.includes('blog')) {
          props.loadPosts({ contentTypeId: postTypeId });
        } else {
          delay(1500).then(() => {
            props.loadPosts({ contentTypeId: postTypeId });
          });
        }

        if (window.location.pathname.includes('portfolio')) {
          props.loadReferences({ contentTypeId: referenceTypeId });
        } else {
          delay(1500).then(() => {
            props.loadReferences({ contentTypeId: referenceTypeId });
          });
        }
      }
    }

    try {
      if (preferredLanguage === 'en') {
        InitClient(spaceId, deliveryAccessToken)
            .then(
                () => {
                    if (config.app_state === 'maintenance') {
                        props.setAppClientState('maintenance');
                        setTimeout(function() {
                        addToast('notify', 'Site is under maintenance.')}, 500);
                    } else {
                      // SET language, setupClientState and add notify
                      props.initLanguage('en');
                      props.setAppClientState('success');
                    }

                    loadData();
                },
                () => {
                  props.setAppClientState('error');
                  setTimeout(function() {addToast('error', 'An error has occurred.', 1, true)}, 1500);
                }
            )
        } else if (preferredLanguage === 'fi') {
          InitClient(spaceId, deliveryAccessToken)
              .then(
                  () => {
                      if (config.app_state === 'maintenance') {
                          props.setAppClientState('maintenance');
                          setTimeout(function() {
                          addToast('notify', 'Sivustoa parannetaan parhaillaan..')}, 500);
                      } else {
                        // SET language, setupClientState and add notify
                        props.initLanguage('fi');
                        props.setAppClientState('success');
                      }

                      loadData();
                  },
                  () => {
                    props.setAppClientState('error');
                    setTimeout(function() {addToast('error', 'Virhe ilmeni.', 1, true)}, 1500);
                  }
              )
        }

      } catch (err) {
        console.error(err);
        props.setAppClientState('error');
      }
  },[]); // ComponentDidMount: notice the empty array here

  const switchLanguage = () => {
    const language = props.language.language === 'fi' ? 'en' : 'fi';
    props.switchLanguage(language);
  }

  const meta = {
    title: "Jussi Vesa Full-Stack Developer | jussivesa.com - Media Engineer, Bachelor Degree with compelling skills and " +
    "knowledge in development, UI & UX design work and working with customers.",
    description: "Vesa is the brand & also my familyname. jussivesa.com represents myself professionally. " +
    "Additionally, I also write my personal blog here, and use this site to showcase my portfolio.",
    canonical: window.location.href,
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'Jussi Vesa, JussiVesa, jussivesa, Full-Stack, Developer, Programmer, UI, UX, Designer'
        }
    }
  };

  const AppRoot = () => {
    return (
      <div>
        <Navigation render={(props) => <Navigation {...props}/> } renderLanguageOptions={true} switchLanguage={switchLanguage} />

        <div style={{ paddingTop: '52px' }}></div>

        <Header render={(props) => <Header {...props}/> } />

        <Fade triggerOnce>
          <div className="col-xs-12"><Profile render={(props) => <Profile {...props}/> } /></div>
        </Fade>

        <div className="col-xs-12"><ReferenceList render={(props) => <ReferenceList {...props}/> }
            references={props.references}
            sections={props.sections} />
        </div>

        <div className="col-xs-12"><SocialFeed render={(props) => <SocialFeed {...props}/> } /></div>

        <Fade triggerOnce>
          <div className="col-xs-12"><Now render={(props) => <Now {...props}/> } /></div>
        </Fade>

        <MainFooter render={(props) => <MainFooter {...props}/> } renderLanguageOptions={true} switchLanguage={switchLanguage} />
      </div>
    )
  }

  const AppChildrenWithLanguageOptions = () => {
    return (
      <div>
          <Navigation render={(props) => <Navigation {...props}/> } renderLanguageOptions={true} switchLanguage={switchLanguage} />
          {props.children}
          <MainFooter render={(props) => <MainFooter {...props}/> } renderLanguageOptions={true} switchLanguage={switchLanguage} />
      </div>
    )
  }

  const AppChildrenWithoutLanguageOptions = () => {
    return (
      <div>
          <Navigation render={(props) => <Navigation {...props}/> } renderLanguageOptions={false} switchLanguage={switchLanguage} />
          {props.children}
          <MainFooter render={(props) => <MainFooter {...props}/> } renderLanguageOptions={false} switchLanguage={switchLanguage} />
      </div>
    )
  }

  const AppSuccess = () => {

    const currentPath = window.location.pathname;

    if (currentPath === "/") {
      return AppRoot();
    } else if (
        currentPath.includes('blog/tagged') || currentPath.includes('blogi/alue') ||
        currentPath.includes('blog/search') || currentPath.includes('blogi/hae')
      ) {
        return AppChildrenWithLanguageOptions();
    } else if (currentPath === '/terms-of-service' || currentPath === '/palveluehdot') {
        return AppChildrenWithLanguageOptions();
    } else if (currentPath === '/privacy-statement' || currentPath === '/yksityisyyden-lausunto') {
       return AppChildrenWithLanguageOptions();
    } else if (currentPath.includes('portfolio-item/') || currentPath.includes('portfolio/')) {
        return AppChildrenWithoutLanguageOptions();
    } else if ((currentPath  === '/blog') || currentPath === '/blogi') {
        return AppChildrenWithLanguageOptions();
    } else if ((currentPath.match(/\//g) || []).length > 1 && currentPath.includes('blog')) {
        return AppChildrenWithoutLanguageOptions();
    } else {
      console.warn('App renders unknown path: ' + currentPath);
      return AppChildrenWithLanguageOptions();
    }

  }

  const DeterminateApp = () => {
    switch (props.app.appClientState) {
      case 'loading':
        return (
          <LoadingAnimation />
        );

      case 'success':
        return AppSuccess();

      case 'error':
        return (
          <ContentfulError render={(props) => <ContentfulError {...props}/> } preferredLanguage={preferredLanguage} />
        )

      case 'maintenance':
        return (
          <div>
              <Navigation render={(props) => <Navigation {...props}/> } renderLanguageOptions={true} switchLanguage={switchLanguage} />
              <UnderMaintenance render={(props) => <UnderMaintenance {...props}/> } />
          </div>
      )

      default:
        return (
          <LoadingAnimation />
        );
    }
  }

  return (
      <DocumentMeta {...meta}>
        <React.Suspense fallback={<LoadingAnimation />}>
        <div className="white">
          {DeterminateApp()}
        </div>
      </React.Suspense>
    </DocumentMeta>
  );
}

App.propTypes = {
  app: PropTypes.object,
  language: PropTypes.object,
  children: PropTypes.object,
  initLanguage: PropTypes.func,
  switchLanguage: PropTypes.func,
  loadNotifications: PropTypes.func,
  loadPosts: PropTypes.func,
  loadSections: PropTypes.func,
  loadReferences: PropTypes.func,
  sections: PropTypes.object.isRequired,
  references: PropTypes.object.isRequired,
  setAppClientState: PropTypes.func
};

export default connectComponent(CSSModules(App, styles))
