import cache from './cache';

export function loadMenus (contentTypeId) {
    return cache.getCachedEntries({
        content_type: contentTypeId,
        order: '-fields.name'
    }).then(payload => {
        return payload.items;
    });
}

export function loadMenu (id) {
    return cache.getCachedEntries({
        'sys.id': id
    }).then(payload => {
        return payload.items[0];
    });
}
