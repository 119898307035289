import { getClient } from './contentfulClient'
import { InitClient } from './contentfulClient'
import config from '../../config'

import { setAppClientState } from './../actions/actionCreators'
import { loadNotifications } from './notificationStore';
import { renderNotifications } from './notificationStore';

export function switchLanguage (language) {
    switch (language) {
        case 'en':
            InitClient(config.contentful.ids.space, config.contentful.tokens.delivery)
                .then(
                    () => setAppClientState('success')
                )
            return getClient().getEntries().then(payload => {
                if (!payload.items.length) {
                    setAppClientState('error');
                    throw new Error('Error on language switch');
                }
                loadNotifications(config.contentful.ids.notificationsTypeId, 'en')
                return { p: payload.items, l: language }
            });

        case 'fi':
            InitClient(config.contentful.ids.space, config.contentful.tokens.delivery)
                .then(
                    () => setAppClientState('success')
                )
            return getClient().getEntries().then(payload => {
                if (!payload.items.length) {
                    setAppClientState('error');
                    throw new Error('Error on language switch');
                }
                loadNotifications(config.contentful.ids.notificationsTypeId, 'fi')
                return { p: payload.items, l: language }
            });
    }
}

export function initLanguage (initialLanguage) {
    switch (initialLanguage) {
        case 'en':
            InitClient(config.contentful.ids.space, config.contentful.tokens.delivery)
                .then(
                    () => setAppClientState('success')
                )
            return getClient().getEntries().then(payload => {
                if (!payload.items.length) {
                    setAppClientState('error');
                    throw new Error('Error on language InitClient');
                }
                renderNotifications(initialLanguage);
                return { p: payload.items, l: initialLanguage }
            });

        case 'fi':
            InitClient(config.contentful.ids.space, config.contentful.tokens.delivery)
                .then(
                    () => setAppClientState('success')
                )
            return getClient().getEntries().then(payload => {
                if (!payload.items.length) {
                    setAppClientState('error');
                    throw new Error('Error on language InitClient');
                }
                renderNotifications(initialLanguage);
                return { p: payload.items, l: initialLanguage }
            });
    }
}
