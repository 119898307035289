import { createClient } from 'contentful';
import config from '../../config/index';

let client;
let authorized;

function usePreview() {
    return config.use_preview;
}

export function InitClient (spaceId, accessToken) {
  const determinateAccessToken = usePreview() ? config.contentful.tokens.delivery_preview : accessToken;
  const determinateUrl = usePreview() ? 'preview.contentful.com' : 'cdn.contentful.com';
  client = createClient({
    space: spaceId,
    accessToken: determinateAccessToken,
    host: determinateUrl
  });
  return client.getSpace()
    .then((space) => {
      authorized = true;
      return space
    })
}

export function getClient () {
  return authorized && client
}
