
import { createBrowserHistory } from 'history'
import { connect } from 'react-redux'
import { applyMiddleware, compose, createStore, bindActionCreators } from 'redux'
import { createRouterMiddleware } from '@lagunovsky/redux-react-router'
import createRootReducer from './reducers'
import logger from 'redux-logger'
import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'
import * as actionCreators from './actions/actionCreators'

import config from '../config/index';

const logRedux = (config.logger.redux_logs == 'true');
const nodeEnv = config.node_env;

// Use state logger if not in production
let middlewares;
if (logRedux) {
  middlewares = [promise, thunk, logger];
} else {
  middlewares = [promise, thunk];
}

function RunDevToolExtensionIfNotInProduction () {
    if (nodeEnv === 'production' && window.__REACT_DEVTOOLS_GLOBAL_HOOK__ &&
        window.__REACT_DEVTOOLS_GLOBAL_HOOK__._renderers &&
        Object.keys(window.__REACT_DEVTOOLS_GLOBAL_HOOK__._renderers).length) {
        window.__REACT_DEVTOOLS_GLOBAL_HOOK__._renderers = {};
    }

    const shouldExposeState = (!logRedux) && window.devToolsExtension;

    return (shouldExposeState ? window.devToolsExtension() : (f) => f)
}

export const history = createBrowserHistory()

export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    compose(
      applyMiddleware(
        createRouterMiddleware(history), // for dispatching history actions
        // ... other middlewares ...
        ...middlewares
      ),
    ),
  )

  RunDevToolExtensionIfNotInProduction();

  return store
}

export const mapStateToProps = state => state;

function mapDispatchToProps (dispatch) {
  return bindActionCreators(actionCreators, dispatch)
}

export function connectComponent (component) {
  return connect(mapStateToProps, mapDispatchToProps)(component)
}
