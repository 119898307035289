import React from 'react'
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules'
import { connectComponent } from '../store'

// Styles
import styles from './Header.css'

function Header(props) {

    // TODO: INITAL state to language / cache
    const language = props.language.language ? props.language.language : 'en';

    return(
        <div>
            {
                Object.keys(props.headers.entries).map(id => {
                    const header = props.headers.entries[id];
                    const lang = header.fields.lang;

                    const get = function(obj, key) {
                        return key.split(".").reduce(function(o, x) {
                            return (typeof o == "undefined" || o === null) ? o : o[x];
                        }, obj);
                    };

                    if (lang === language) {
                        const headerBg = get(header, "fields.headerImage.fields.file.url");
                        if (headerBg) {
                            return (
                                <div
                                    key={id}
                                    id="top-image"
                                    styleName="header-container-bg"
                                    style={{
                                        'backgroundSize': 'cover',
                                        'background': 'url("' + header.fields.headerImage.fields.file.url + '?fm=webp&h=800") center',
                                        'backgroundRepeat': 'no-repeat',
                                    }}
                                    alt={header.fields.headerImage.fields.description}>
                                    <div className="row col-xs-12" styleName="header-top">
                                        <p>{header.fields.name}</p>
                                        <h1 styleName="header-main-header">{header.fields.header}</h1>
                                    </div>
                                    <div className="row col-xs-12">
                                        <div className="col-xs-3 col-sm-3" styleName="header-left">
                                            <p><b>{header.fields.header2}</b></p>
                                        </div>
                                        <div className="col-xs-9 col-sm-9" styleName="header-right">
                                            <p className="col-xs-12 col-sm-6">{header.fields.description}</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        } else {
                            const imgUrl = header.fields.headerClipImage.fields.file.url + "?fm=webp";
                            return(
                                <div key={id}>
                                    <div styleName="header-container">
                                        <div className="col-xs-12" styleName="header-top">
                                            <p>{header.fields.name}</p>
                                            {/*<h1>{header.fields.header}</h1>*/}
                                            <h1
                                                style={{
                                                    'backgroundImage':
                                                    'url(' + imgUrl + ')',
                                                }} styleName="clip-text">{header.fields.header}</h1>
                                        </div>
                                        <div className="col-xs-12" styleName="header-bottom">
                                            <div className="col-xs-3 col-sm-3" styleName="header-left">
                                                <p><b>{header.fields.header2}</b></p>
                                            </div>
                                            <div className="col-xs-9 col-sm-9" styleName="header-right">
                                                <p className="col-xs-12 col-sm-6">{header.fields.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    }
                })
            }
        </div>
    );
}

Header.propTypes = {
    language: PropTypes.object,
    headers: PropTypes.object
};

export default connectComponent(CSSModules(Header, styles))
