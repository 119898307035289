import React from 'react'
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules'
import { connectComponent } from '../store'
import styles from './UnderMaintenance.css'

import FacebookMessengerBtn from './FacebookMessengerBtn'

function UnderMaintenance(props) {
    if (props.language.language === 'en' || props.language.language === undefined) {
        return (
            <div className="col-xs-12 text-center" styleName="undermaintenance-container">
                <h4>The site is currently under maintenance and will be available soon.</h4>
                <p>If you have urgent matters, please contact me directly:&nbsp;</p>
                <div styleName="contact-link">
                    <FacebookMessengerBtn addClass="line-animation" lang={ 'en' } />
                </div>
                <div styleName="contact-link">
                    <a className="line-animation" href="mailto:jussi.vesa@gmail.com" rel="noopener noreferrer" target="_blank">Send mail</a>
                </div>
                <p styleName="later-on">Please, check back later on. Thank you for <i>your</i> understanding!</p>
            </div>
        )
    } else {
        return (
            <div className="col-xs-12 text-center" styleName="undermaintenance-container">
                <h4>Sivustolle tehdään juuri parannuksia ja se on saatavilla uudelleen pian.</h4>
                <p>Jos sinulla on kiireistä asiaa, ole hyvä ja ota minuun suoraan yhteyttä:&nbsp;</p>
                <div styleName="contact-link">
                    <FacebookMessengerBtn addClass="line-animation" lang={ 'fi' } />
                </div>
                <div styleName="contact-link">
                    <a className="line-animation" href="mailto:jussi.vesa@gmail.com" rel="noopener noreferrer" target="_blank">Lähetä postia</a>
                </div>
                <p styleName="later-on">Ole hyvä ja palaa myöhemmin. Kiitos <i>sinulle</i> ymmärryksestäsi!</p>
            </div>
        )
    }
}

UnderMaintenance.propTypes = {
    language: PropTypes.object
}

export default connectComponent(CSSModules(UnderMaintenance, styles))
