import React from 'react';
import PropTypes from 'prop-types';

function FacebookMessengerBtn(props) {

    const language = props.lang;
    const addClass = props.addClass;

    return(
            language === 'en'
            ?
                <a className={addClass} rel="noopener noreferrer" target="_blank" href="https://m.me/jussi.vesa">Messenger</a>
            :
                <a className={addClass} rel="noopener noreferrer" target="_blank" href="https://m.me/jussi.vesa">Messenger</a>
    )
}

FacebookMessengerBtn.propTypes = {
    lang: PropTypes.string,
    addClass: PropTypes.string,
};


export default FacebookMessengerBtn
