import cache from './cache';

export function loadSkills (contentTypeId) {
    return cache.getCachedEntries({
        content_type: contentTypeId,
        order: '-fields.rate'
    }).then(payload => {
        return payload.items;
    });
}

export function loadSkill (id) {
    return cache.getCachedEntries({
        'sys.id': id
    }).then(payload => {
        return payload.items[0];
    });
}
