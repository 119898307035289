
import React from 'react'
import PropTypes from 'prop-types'
import CSSModules from 'react-css-modules'
import { connectComponent } from '../store'

import styles from './ContentfulError.css'
import FacebookMessengerBtn from './FacebookMessengerBtn'

function ContentfulError(props) {
    const currentPath = window.location.pathname;

    if (props.preferredLanguage === 'en') {
        return (
            <div className="col-xs-12" styleName="error-container">
                <div className="text-center">
                    <h3 styleName="error-header">Unfortunately, page &quot;{currentPath}&quot; is not working as expected...</h3>
                </div>
                <div className="text-center" styleName="info-text">
                    <p>If you have urgent matters, please contact me directly </p>
                    <a className="btn-flat black white-text" href="mailto:jussi.vesa@gmail.com" rel="noopener noreferrer" target="_blank">Send Mail</a>
                    <p style={{'marginTop': '15px'}}> or </p>
                    <button className="btn btn-flat black white-text">
                        <FacebookMessengerBtn lang={props.preferredLanguage} />
                    </button>
                </div>
                <div className="text-center" styleName="info-text-2">
                    <p>You can always return to the <a className="line-animation" href="/">homepage</a></p>
                    <p><strong>Thank you</strong> for <i>your</i> understanding. </p>
                </div>
            </div>
        );
    } else if (props.preferredLanguage === 'fi') {
        return (
            <div className="col-xs-12" styleName="error-container">
                <div className="text-center">
                    <h3 styleName="error-header">Valitettavasti sivu &quot;{currentPath}&quot; ei toimi odotetusti...</h3>
                </div>
                <div className="text-center" styleName="info-text">
                    <p>Jos sinulla on kiireistä asiaa, ole hyvä ja ota minuun yhteyttä suoraan </p>
                    <a className="btn-flat black white-text" href="mailto:jussi.vesa@gmail.com" rel="noopener noreferrer" target="_blank">Lähetä Sähköposti</a>
                    <p style={{'marginTop': '15px'}}> tai </p>
                    <button className="btn btn-flat black white-text">
                        <FacebookMessengerBtn lang={props.preferredLanguage}/>
                    </button>
                </div>
                <div className="text-center" styleName="info-text-2">
                    <p>Voit aina palata takaisin <a className="line-animation" href="/">etusivulle</a></p>
                    <p><strong>Kiitos</strong><i> sinulle </i> ymmärryksestäsi. </p>
                </div>
            </div>
        );
    }
}

ContentfulError.propTypes = {
    preferredLanguage: PropTypes.string,
};

export default connectComponent(CSSModules(ContentfulError, styles));
