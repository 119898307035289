import { makeReducer } from './util'

export const references = makeReducer(function (action) {
  switch (action.type) {
    case 'LOAD_REFERENCES_PENDING':
      return { fetching: true };
    case 'LOAD_REFERENCES_FULFILLED':
      return {
        fetching: false,
        entries: action.payload.reduce((collection, entry) => {
          collection[ entry.sys.id ] = entry;
          return collection
        }, {})
      };
    case 'LOAD_REFERENCES_REJECTED':
      return { error: true, fetching: false };

    case 'LOAD_A_REFERENCE_PENDING':
      return {
        entries: {
          [ action.meta.id ]: {
            fetching: true
          }
        }
      };
    case 'LOAD_A_REFERENCE_FULFILLED':
      action.payload.fetching = false;
        
      return {
        entries: {
          [ action.meta.id ]: action.payload
        }
      };
    case 'LOAD_A_REFERENCE_REJECTED':
      return {
        entries: {
          [ action.meta.id ]: {
            error: true,
            fetching: false
          }
        }
      }
  }
}, { entries: [] });
