

/**
 * Jussivesa.com API + DB + Storage Account
 */
const localconfig = {
    app_state: '', // 'maintenance'
    contentful: {
        use_preview: false,
        tokens: {
            delivery: '14ee581c1815fab507ba8ee74f47b452b7c6cd4cbe2bfff0f36a14db45544404',
            delivery_preview: '7b30a0ed4bf79841bbd55c4810be3288245147884fe6d98070b9ae7483bd3ecc'
        },
        ids: {
            space: '38cszqj8p9wk',
            galleryType: '7leLzv8hW06amGmke86y8G',
            postType: '9fbb59098edb1705683245c8e7b2580ed0-post',
            referenceType: 'bbecdf1cc9550adaa23ccbd8140a4207319a9333bf95f330e8f1c-reference',
            skillType: '52ae86030167f499f0aced31982e8ebd88a4d3723b57b7daa708b3e8-skill',
            headerType: '5ea78501ef18b981576f631da78d36bf90f2e1b2b916dfc9b7420abf-header',
            footerType: '26527919415016e746a44f52022b38a0f8e4efaad3c2ba5bb053b4a5-footer',
            menuItemType: '12c7337eba5a0f22b5fe7b6d8302d88d9f9a45ae9371AJFj82mma3a-menuItem',
            authorType: '38nK0gXXIccQ2IEosyAg6C',
            sectionType: 'as291d3419838aa95uwswho8523bfe20616b6aaa17c43basdas97ea-section',
            dataJsonType: 'b135019e4702de4ac86bf8bad5af87976c0dc8e78cbf1924df33d19-dataJson',
            notificationsTypeId: '75871ae15c32573f7ea3eb9866f347f1ca4fe6-notifications'
        }
    },
    logger: {
        level: 'info',
        format: 'tiny',
        redux_logs: 'true'
    },
};

module.exports = localconfig;
