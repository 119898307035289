// AJAX call methods, sleek!
import axios from 'axios';
import config from '../../config/index';
const PROD_IDENTIFYUSERS_URL = config.api.production.urls.identifyUsers;
const LOCAL_IDENTIFYUSERS_URL = config.api.localhost.urls.identifyUsers;

let addToast = function(type, message, duration, showUntilDismiss) {
    let toaster = document.getElementsByClassName('toaster')[0];
    const toast = document.createElement('div');
    toast.classList.add('toast');
    toast.classList.add('toast--' + type);
    toast.innerHTML ='<p class="toast-content"> ' + message + '</p>';
    toaster.prepend(toast);

    if (showUntilDismiss) {
        toast.classList.add('toast-dismiss');
        toast.innerHTML += '<i class="toast-dismiss-icon"> | OK</i>';
    }

    toast.addEventListener('transitionend', function (event) {
        if (event.propertyName !== 'transform') return;

        if (toast.classList.contains('toast--show')) {

            if (toast.classList.contains('toast-dismiss')) {
                this.children[0].onclick = function() {
                    toast.classList.remove('toast--show');
                }
            } else {
                setTimeout(function () {
                    toast.classList.remove('toast--show')
                }, duration ? duration : 3000)
            }
        } else {
            toaster.removeChild(toast)
        }
    }, false);
    setTimeout(() => toast.classList.add('toast--show'), 100)
};

let addToastWithGa_id = function(type, message, g_id, read_id) {
    const notifications = document.getElementById('notifications');
    const toast = document.createElement('div');
    toast.classList.add('toast');
    toast.classList.add('toast--' + type);
    toast.innerHTML = '<p class="toast-content"> ' + message + '</p>';
    notifications.prepend(toast);

    toast.classList.add('toast-dismiss')

    toast.innerHTML += '<i class="toast-dismiss-icon"> | OK</i>';
    toast.innerHTML += '<i class="toast-cancel-icon visually-hidden"> | <i  class="fa fa-undo"/> 4</i>';

    toast.setAttribute('g_id', g_id);
    toast.setAttribute('read_id', read_id);


    toast.addEventListener('transitionend', function (event) {
        if (event.propertyName !== 'transform') return;

        if (toast.classList.contains('toast--show')) {

            if (toast.classList.contains('toast-dismiss')) {
                var seconds = 3;
                var second = 0;
                var _this = this;

                this.children[1].onclick = function() {
                    _this.children[2].classList.remove('visually-hidden');
                    var interval = setInterval(function() {
                        _this.children[2].innerHTML  = " | <i class='fa fa-undo' /> " + (seconds - second);
                        if (second >= seconds) {
                            clearInterval(interval);
                        }
                        second++;
                    }, 1000);

                    // Hide OK, show Cancel
                    _this.children[1].classList.add('visually-hidden');
                    _this.children[2].classList.remove('visually-hidden');

                    var remove = setTimeout(function(){
                        toast.classList.remove('toast--show');
                        const toast_g_id = toast.attributes.g_id.value;
                        const toast_read_id = toast.attributes.read_id.value;

                        axios.put(window.location.hostname == 'localhost'
                            ? LOCAL_IDENTIFYUSERS_URL + '/set-to-read/' + toast_g_id + '/' + toast_read_id
                            : PROD_IDENTIFYUSERS_URL + '/set-to-read/' + toast_g_id + '/' + toast_read_id )
                            .catch(err => {
                                console.debug(JSON.stringify(err, Object.getOwnPropertyNames(err), 4));
                            });
                    }, 3500);

                    _this.children[2].onclick = function() {

                      // Show OK
                      _this.children[1].classList.remove('visually-hidden');

                      // Hide Cancel, reset content
                      _this.children[2].classList.add('visually-hidden');
                      _this.children[2].innerHTML = '| <i  class="fa fa-undo"/> 3</i>';

                        clearTimeout(remove);
                        clearTimeout(interval);
                    };
                };

            } else {
                setTimeout(function () {
                    toast.classList.remove('toast--show')
                }, 3000)
            }
        } else {
            notifications.removeChild(toast)
        }
    }, false);
    setTimeout(() => toast.classList.add('toast--show'), 100)
};

const _addToast = addToast;
const _addToastWithGa_id = addToastWithGa_id;
export { _addToastWithGa_id as addToastWithGa_id, _addToast as addToast };
