import config from './config/index';
// NOTICE: Re-start node prod-dist-server.js each time .env changes!!! Wont take effect until restart.
export const deliveryAccessToken = config.contentful.tokens.delivery;
export const deliveryAccessToken_Preview = config.contentful.tokens.delivery_preview;
export const spaceId = config.contentful.ids.space;

export const galleryTypeId = config.contentful.ids.galleryType;
export const postTypeId = config.contentful.ids.postType;
export const referenceTypeId = config.contentful.ids.referenceType;
export const skillTypeId = config.contentful.ids.skillType;
export const headerTypeId = config.contentful.ids.headerType;
export const footerTypeId = config.contentful.ids.footerType;
export const menuItemTypeId = config.contentful.ids.menuItemType;
export const authorTypeId = config.contentful.ids.authorType;
export const sectionTypeId = config.contentful.ids.sectionType;
export const dataJsonTypeId = config.contentful.ids.dataJsonType;
export const notificationsTypeId = config.contentful.ids.notificationsTypeId;
export const googleMapsAPI = config.google.apis.maps;