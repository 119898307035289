import {getClient} from './contentfulClient'
import axios from 'axios'
import moment from 'moment';
import { getCurrentBrowserFingerPrint } from '@rajesh896/broprint.js';

import config from '../../config/index';

let preferredLanguage = localStorage.getItem('prefLang');

import { addToast, addToastWithGa_id } from './../helpers/notification';

const PROD_IDENTIFYUSERS_URL = config.api.production.urls.identifyUsers;
const LOCAL_IDENTIFYUSERS_URL = config.api.localhost.urls.identifyUsers;

/**
 * No caching as of now
 */
export function loadNotifications (contentTypeId, newLang) {
    // If we are asking new language for notifications
    if (newLang) {
      localStorage.setItem('prefLang', newLang);
      preferredLanguage = newLang;
      // Clear prev notifications since they are in wrong language
      var div = document.getElementById('notifications');
      while(div.firstChild){
          div.removeChild(div.firstChild);
      }
      // Get new notifications
      renderNotifications(newLang);
    }

    return getClient().getEntries({
        content_type: contentTypeId
    }).then(payload => {
        getCurrentBrowserFingerPrint().then((browserId) => {
            Object.keys(payload.items).map((id) => {

                const notification = payload.items[ id ];

                window.location.hostname == 'localhost' ?
                    axios.put(LOCAL_IDENTIFYUSERS_URL + '/update/' + browserId, {
                        show_until: notification.fields.show_until,
                        content: notification.fields.content,
                        read_id: notification.fields.read_id,
                        lang: notification.fields.lang
                    })
                    .then((response) =>{
                        if (response.data.content && response.data.lang && response.data.read_id) {
                            maybeShowNotification(response.data, browserId);
                        }
                    })
                    .catch(err => {
                        console.debug(JSON.stringify(err, Object.getOwnPropertyNames(err), 4));
                    })
                    :
                    axios.put(PROD_IDENTIFYUSERS_URL + '/update/' + browserId, {
                        show_until: notification.fields.show_until,
                        content: notification.fields.content,
                        read_id: notification.fields.read_id,
                        lang: notification.fields.lang
                    })
                    .then((response) => {
                        if (response.data.ok === 1) {
                            maybeShowNotification(response.data, browserId);
                        }
                    })
                    .catch(err => {
                        console.debug(JSON.stringify(err, Object.getOwnPropertyNames(err), 4));
                    });
            });
        });

        return payload.items
    })
}

export function renderNotifications(newLang) {
    getCurrentBrowserFingerPrint().then((browserId) => {

        const user_has_unread_read_id_objects = [];
        const user_all_read_obj = [];
        const route = window.location.hostname == 'localhost' ? LOCAL_IDENTIFYUSERS_URL + '/get/' + browserId : PROD_IDENTIFYUSERS_URL + '/get/' + browserId;

        axios.get(route)
            .then(res => {
            // If returned data is the current user
            if (res.data[0] !== undefined && res.data[0]._id !== undefined) {
                if (res.data[0]._id == browserId) {
                    res.data[0].read_objects.map(function(d) {
                        // Add all notifications that are not yet read (User has not clicked OK, read_state == false)
                        // Requires that language is correct
                        if (d.read_state !== true && d.lang === preferredLanguage) {
                            user_has_unread_read_id_objects.push(d);
                        }
                        user_all_read_obj.push(d);
                    });
                }
            }

            // Display un-read notifications FROM MONGODB!
            if (user_has_unread_read_id_objects.length > 0) {
                // Use data from DB, has every details as Contentful model
                user_has_unread_read_id_objects.map(function(data) {
                    maybeShowNotification(data, browserId);
                });
                newLang === 'en' ? addToast('success', 'Language changed.') : addToast('success', 'Kieli vaihdettu.')
            } else {
                // No read_id's that are not yet read (User cleared all or no read_id's exist)
            }
        })
        .catch(err => {
            console.debug(JSON.stringify(err, Object.getOwnPropertyNames(err), 4));
        });
    });
}

const maybeShowNotification = (entry, browserId) => {
    const now = moment(new Date());
    const obj_show_until = moment(entry.show_until);
    if (now <= obj_show_until) {
        if (preferredLanguage === 'en' && entry.lang === 'en') {
            addToastWithGa_id('notify', entry.content, browserId, entry.read_id);
        }
        if (preferredLanguage === 'fi' && entry.lang === 'fi') {
            addToastWithGa_id('notify', entry.content, browserId, entry.read_id);
        // Default when no preferred lang set
        }
        if (!preferredLanguage) {
            // We default to fi
            if (entry.lang === 'fi') {
                addToastWithGa_id('notify', entry.content, browserId, entry.read_id);
            }
        }
    }
}
