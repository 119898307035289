import { makeReducer } from './util'

export const skills = makeReducer(function (action) {
    switch (action.type) {
        case 'LOAD_SKILLS_PENDING':
            return { fetching: true };
        case 'LOAD_SKILLS_FULFILLED':
            return {
                fetching: false,
                entries: action.payload.reduce((collection, entry) => {
                    collection[ entry.sys.id ] = entry;
                    return collection
                }, {})
            };
        case 'LOAD_SKILLS_REJECTED':
            return { error: true, fetching: false };

        case 'LOAD_A_SKILL_PENDING':
            return {
                entries: {
                    [ action.meta.id ]: {
                        fetching: true
                    }
                }
            };
        case 'LOAD_A_SKILL_FULFILLED':
            action.payload.fetching = false;

            return {
                entries: {
                    [ action.meta.id ]: action.payload
                }
            };
        case 'LOAD_A_SKILL_REJECTED':
            return {
                entries: {
                    [ action.meta.id ]: {
                        error: true,
                        fetching: false
                    }
                }
            }
    }
}, { entries: [] });
