import { combineReducers } from 'redux'
import { createRouterReducer } from '@lagunovsky/redux-react-router'
import { app } from './app'
import { language } from './language'

import { galleries } from './galleries'
import { posts } from './posts'
import { references } from './references'
import { skills } from './skills'
import { footers } from './footer'
import { headers } from './header'
import { menus } from './menu'
import { authors } from './author'
import { sections } from './section'
import { dataJson } from './dataJson'
import { notifications } from './notification'

const createRootReducer = (history) => combineReducers(
    {
        router: createRouterReducer(history),
        // Rest of reducers
        app,
        language,
        galleries,
        posts,
        references,
        skills,
        footers,
        headers,
        menus,
        authors,
        sections,
        dataJson,
        notifications,
    }
);

export default createRootReducer
