import React from 'react';
import { hydrateRoot, createRoot } from 'react-dom/client';

// import registerServiceWorker from './registerServiceWorker';
import { Route, Routes } from 'react-router-dom'
import { ReduxRouter } from '@lagunovsky/redux-react-router'

// Notice! DO NOT use {} for components to Router
import App from './components/App'

import { Provider } from 'react-redux'
import configureStore, { history } from './store'


import './styles/main.scss';

/**
 * https://www.debugbear.com/blog/bundle-splitting-components-with-webpack-and-react
 *
 * Lazy Load components and use React.Suspense
 */
const PostList = React.lazy(() => import('./components/PostList'));
const Post = React.lazy(() => import('./components/Post'));
const SingleReference = React.lazy(() => import('./components/SingleReference'));
const Terms = React.lazy(() => import('./components/Terms'));
const Privacy = React.lazy(() => import('./components/Privacy'));
const AuthPage = React.lazy(() => import('./components/AuthPage'));
const NoMatch = React.lazy(() => import('./components/NoMatch'));

const store = configureStore(/* provide initial state if any */);

const router = (
    <Provider store={store}>
        <ReduxRouter history={history}>
            <App element={<App /> }>
                <Routes>
                    <Route exact path='/blog' element={<PostList />} />
                    <Route path='/blog/:date/:slug' element={<Post />} />

                    <Route exact path='/blogi' element={<PostList />} />
                    <Route path='/blogi/:date/:slug' element={<Post />} />

                    <Route path='/blog/tagged' element={<PostList />} />
                    <Route path='/blog/tag' element={<PostList />} />
                    <Route path='/blogi/alue' element={<PostList />} />

                    <Route path='/blog/search' element={<PostList />} />
                    <Route path='/blogi/hae' element={<PostList />} />

                    <Route exact path='/login' element={<AuthPage />} />
                    <Route exact path='/kirjaudu' element={<AuthPage />} />

                    <Route exact path='/portfolio-item/:slug' element={<SingleReference />} />
                    <Route exact path='/portfolio/:slug' element={<SingleReference />} />

                    <Route exact path='/privacy-statement' element={<Privacy />} />
                    <Route exact path='/yksityisyyden-lausunto' element={<Privacy />} />

                    <Route exact path='/terms-of-service' element={<Terms />} />
                    <Route exact path='/palveluehdot' element={<Terms />} />

                    <Route path='/not-found' element={<NoMatch />} />
                    <Route element={<NoMatch />} />
                </Routes>
            </App>
        </ReduxRouter>
    </Provider>
);

const rootElement = document.querySelector('main');
if (rootElement.hasChildNodes()) {
  hydrateRoot(rootElement, router);
} else {
  createRoot(rootElement).render(router);
}

// registerServiceWorker();