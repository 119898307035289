'use strict';

const config = {
    stripe_secret: process.env.STRIPE_SECRET,
    secret: process.env.SECRET,
    gmail_app_secret: process.env.GMAIL_APP_SECRET,
    port: process.env.PORT,
    api_port: process.env.API_PORT,
    node_env: process.env.NODE_ENV,
    app_state: process.env.APP_STATE,
    azure: {
        storage_connection: process.env.AZURE_STORAGE_CONNECTION_STRING
    },
    api: {
        localhost: {
            urls: {
                login: process.env.LOCALHOSTLOGINURL,
                register: process.env.LOCALHOSTREGISTERULR,
                comments: process.env.LOCALHOSTCOMMENTSURL,
                posts: process.env.LOCALHOSTPOSTSURL,
                identifyUsers: process.env.LOCALHOSTIDENTIFYUSERURL,
            },
        },
        production: {
            urls: {
                login: process.env.PRODUCTIONLOGINURL,
                register: process.env.PRODUCTIONREGISTERURL,
                comments: process.env.PRODUCTIONCOMMENTSURL,
                posts: process.env.PRODUCTIONPOSTSURL,
                identifyUsers: process.env.PRODUCTIONIDENTIFYUSERURL,
            },
        }

    },
    contentful: {
        use_preview: process.env.USE_PREVIEW,
        tokens: {
            delivery: process.env.DELIVERYACCESSTOKEN,
            delivery_preview: process.env.DELIVERYACCESSTOKEN_PREVIEW,
        },
        ids: {
            space: process.env.SPACEID,
            galleryType: process.env.GALLERYTYPEID,
            postType: process.env.POSTTYPEID,
            referenceType: process.env.REFERENCETYPEID,
            skillType: process.env.SKILLTYPEID,
            headerType: process.env.HEADERTYPEID,
            footerType: process.env.FOOTERTYPEID,
            menuItemType: process.env.MENUITEMTYPEID,
            authorType: process.env.AUTHORTYPEID,
            sectionType: process.env.SECTIONTYPEID,
            dataJsonType: process.env.DATAJSONTYPEID,
            notificationsTypeId: process.env.NOTIFICATIONSTYPEID,
        }
    },
    facebook: {
        page_id: process.env.FACEBOOK_PAGE_ID,
        app_id: process.env.FACEBOOK_APP_ID,
    },
    google: {
        apis: {
            maps: process.env.GOOGLEMAPSAPI,
        },
    },
    o365: {
        user: process.env.O365_USER,
        pass: process.env.O365_PASS
    },
    database: {
        connection: {
            server: process.env.MONGO_SERVER,
            user: process.env.MLAB_USER,
            password: process.env.MLAB_PASSWORD,
        }
    },
    logger: {
        level: 'info',
        format: 'tiny',
        redux_logs: process.env.REDUX_LOG_STATE
    },
    integration: {
        apiToken: process.env.INTEGRATION_API_TOKEN
    }
};

module.exports = config;
