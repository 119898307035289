import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';

import { connectComponent } from '../store'

import MenuItem from '../components/MenuItem'

function Navigation(props) {

    const [notificationsIsHidden, setNotificationsIsHidden] = useState(localStorage.getItem('notifyToggleState'));
    const [firstClick, setFirstClick] = useState(true);

    useEffect(() => {
        // Load Header
        const { headerTypeId } = props.app;
        props.loadHeaders({ contentTypeId: headerTypeId });

        // Load MenuItem, dunno why here but works..
        const { menuItemTypeId } = props.app;
        props.loadMenus({ contentTypeId: menuItemTypeId });

        // Check state with toggle, if hide notifications
        if (notificationsIsHidden === true || notificationsIsHidden == "true") {
            setNotificationsIsHidden(true);
            toggleNotifications();
        }
    },[]); // ComponentDidMount: notice the empty array here

    const toggleNotifications = () => {
        if (notificationsIsHidden){
            document.getElementById('notify-count')?.classList?.toggle('visually-hidden');
            document.getElementById('notifications')?.classList?.toggle('toaster--toggle');
            document.getElementsByClassName('notification-icon')[0]?.classList?.toggle('fa-bell-slash');
            document.getElementsByClassName('notification-icon')[0]?.classList?.toggle('fa-bell');
            localStorage.setItem('notifyToggleState', true);
        } else {
            document.getElementById('notify-count')?.classList?.toggle('visually-hidden');
            document.getElementById('notifications')?.classList?.toggle('toaster--toggle');
            document.getElementsByClassName('notification-icon')[0]?.classList?.toggle('fa-bell-slash');
            document.getElementsByClassName('notification-icon')[0]?.classList?.toggle('fa-bell');
            localStorage.setItem('notifyToggleState', false);
        }

        if (firstClick) {
            document.getElementsByClassName('notification-icon')[0]?.classList?.remove('pulse-animation');
        } else {
            setFirstClick(true);
            setNotificationsIsHidden(!notificationsIsHidden);
        }

        setNotificationsIsHidden(!notificationsIsHidden);
    }

    const switchLanguage = () => {
        const language = props.language.language === 'fi' ? 'en' : 'fi';
        props.switchLanguage(language);
    }

    return (
        <nav>
            <div className="nav">
                <div className="blurred-nav navbar-fixed-top">
                    <ul>
                        <MenuItem />
                        {
                            props.renderLanguageOptions ?
                            props.language.language === 'fi'
                                ? <li>
                                    <p>
                                        <span  className="c-d-2 line-animation clickable" onClick={switchLanguage}>In English</span>
                                        <i onClick={toggleNotifications} className="pulse-animation notification-icon c-d-1 fa fa-bell clickable"></i>
                                        <span id="notify-count" onClick={toggleNotifications} className="visually-hidden pulse-animation notify-count red white-text clickable">{document.getElementById('notifications').childElementCount}</span>
                                    </p>
                                </li>
                                : <li>
                                    <p>
                                        <span className="c-d-2 line-animation clickable" onClick={switchLanguage}>Suomeksi</span>
                                        <i onClick={toggleNotifications} className="pulse-animation notification-icon c-d-1 fa fa-bell clickable"></i>
                                        <span id="notify-count" onClick={toggleNotifications} className="visually-hidden pulse-animation notify-count red white-text clickable">{document.getElementById('notifications').childElementCount}</span>
                                    </p>
                                </li>
                            : null
                        }
                    </ul>
                </div>
            </div>
        </nav>
    );
}

Navigation.propTypes = {
    app: PropTypes.object,
    loadMenus: PropTypes.func,
    loadHeaders: PropTypes.func,
    language: PropTypes.object,
    switchLanguage: PropTypes.func,
    renderLanguageOptions: PropTypes.bool
};

export default connectComponent(Navigation)
