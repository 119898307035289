
import React from 'react'
import PropTypes from 'prop-types'
import CSSModules from 'react-css-modules'
import { connectComponent } from '../store'
import styles from './LoadingAnimation.css'
// import Navigation from './Navigation'

class LoadingAnimation extends React.Component {
    render () {
        return <></>
        /*
        return(
            <div>
                <Navigation renderLanguageOptions={true}/>
                <div styleName="spinner-container">
                    <div styleName="offline-text">
                        <span>JUSSI VESA</span>
                        <h1>Ohjelmistoarkkitehti ja kehittäjä</h1>
                        <h2>Lyhyesti</h2>
                        <p>Olen mediatekniikan insinööri ja omaan laajat taidot sekä tietämyksen ohjelmistokehityksestä, UI & UX suunnittelusta, sekä asiakasrajapinnassa toimimisesta.</p>
                    </div>
                    <div styleName="spinner">
                        <svg styleName="sprinner-svg" height="64" viewBox="0 0 24 24" width="64" xmlns="http://www.w3.org/2000/svg" className="k-rotate">
                            <path d="M12 4V1L8 5l4 4V6c3.31 0 6 2.69 6 6 0 1.01-.25 1.97-.7 2.8l1.46 1.46C19.54 15.03 20 13.57 20 12c0-4.42-3.58-8-8-8zm0 14c-3.31 0-6-2.69-6-6 0-1.01.25-1.97.7-2.8L5.24 7.74C4.46 8.97 4 10.43 4 12c0 4.42 3.58 8 8 8v3l4-4-4-4v3z"/>
                            <path d="M0 0h24v24H0z" fill="none"/>
                        </svg>
                    </div>
                </div>
            </div>
        );
        */
    }
}

LoadingAnimation.propTypes = {
    app: PropTypes.object
};

export default connectComponent(CSSModules(LoadingAnimation, styles))
