import { makeReducer } from './util'

export const dataJson = makeReducer(function (action) {
    switch (action.type) {
        case 'LOAD_DATA_JSON_PENDING':
            return { fetching: true };
        case 'LOAD_DATA_JSON_FULFILLED':
            return {
                fetching: false,
                entries: action.payload.reduce((collection, entry) => {
                    collection[ entry.sys.id ] = entry;
                    return collection
                }, {})
            };
        case 'LOAD_DATA_JSON_REJECTED':
            return { error: true, fetching: false }
    }
}, { entries: [] });
