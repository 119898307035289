import { getClient } from './contentfulClient'

class Singleton {

    constructor () {
      if (!Singleton.instance) {
        console.log('Cache created...', Date.now());
        Singleton.instance = this
      }
      // Initialize object
      return Singleton.instance
    }

    // Properties
    cache = {};

    // Methods
    key(contentTypeOrId, order, fields) {
        return `${contentTypeOrId}_${order}_${JSON.stringify(fields)}`;
    }

    value(payload) {
        return {
            datetime:  Date.now(),
            ...payload
        }
    }

    getCachedEntries(parameters) {
        return new Promise((resolve, reject) => {
            // Validate parameters
            if (typeof parameters === 'undefined') return reject('Cache empty parameters provided!');

            const order = parameters['order'] ? parameters['order'] : '';
            const fields = parameters['fields'] ? parameters['fields'] : '';

            // All entries of content type
            if (parameters['content_type']) {

                const cacheKey = this.key(parameters['content_type'], order, fields);

                if (this.cache[cacheKey]) {
                    return resolve(this.cache[cacheKey])
                } else {
                    const client = getClient();
                    if (!client) {
                        console.info('No client, creating new');
                        return;
                    }

                    return client.getEntries({
                        content_type: parameters['content_type'],
                        order: order,
                        fields: fields
                    }).then(payload => {
                        const cacheValue = this.value(payload);
                        this.cache[cacheKey] = cacheValue;
                        return resolve(this.cache[cacheKey]);
                    });
                }
            }

            // Single entry
            if (parameters['sys.id']) {

                // No order or fields for single value, but use the same method still for key
                const cacheKey = this.key(parameters['sys.id'], order, fields);

                if (this.cache[cacheKey]) {
                    return resolve(this.cache[cacheKey])
                } else {
                    const client = getClient();
                    if (!client) {
                        console.error('No client, could not getEntries');
                        return;
                    }

                    return client.getEntries({
                            'sys.id': parameters['sys.id'],
                    }).then(payload => {
                        if (!payload.items.length) {
                            return reject('Entry "' + parameters['sys.id'] + '" not found');
                        }
                        const cacheValue = this.value(payload);
                        this.cache[cacheKey] = cacheValue;
                        return resolve(this.cache[cacheKey]);
                    });
                }
            }

            return reject('Cache unknown parameters provided!');
        });
    }
}

const instance = new Singleton()
Object.freeze(instance)

export default instance;
