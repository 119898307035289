import {
  deliveryAccessToken,
  spaceId,
  galleryTypeId,
  postTypeId,
  referenceTypeId,
  skillTypeId,
  headerTypeId,
  footerTypeId,
  menuItemTypeId,
  authorTypeId,
  sectionTypeId,
  dataJsonTypeId,
  notificationsTypeId,
} from '../../contentful-config'

import { makeReducer } from './util'

export const app = makeReducer(function (action) {
  switch (action.type) {
    case 'SET_APP_CLIENT_STATE':
      return { appClientState: action.appClientState }
  }
}, {
  appClientState: 'loading',
  deliveryAccessToken,
  spaceId,
  dataJsonTypeId,
  sectionTypeId,
  authorTypeId,
  menuItemTypeId,
  headerTypeId,
  footerTypeId,
  skillTypeId,
  galleryTypeId,
  postTypeId,
  referenceTypeId,
  notificationsTypeId
});
