import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules'
import { Link } from 'react-router-dom'
import { connectComponent } from '../store'
import moment from 'moment';

import styles from './MainFooter.css'
// Markdown
import Markdown from 'react-markdown'

// Url format helper
import { urlPathToPrettyText } from '../helpers/urlPathToPrettyText';
import { parseURL } from '../helpers/prettifyURL';

function MainFooter(props) {

    useEffect(() => {
        const { footerTypeId } = props.app;
        props.loadFooters({ contentTypeId: footerTypeId })
    },[]); // ComponentDidMount: notice the empty array here

    const switchLanguage = () => {
        const language = props.language.language === 'fi' ? 'en' : 'fi';
        props.switchLanguage(language);
    }

    const language = props.language.language ? props.language.language : 'en';
    const maybeLanguageOptions = props.renderLanguageOptions;

    return (
        <footer className="col-xs-12 white c-d-1">
            {
                Object.keys(props.footers.entries).map(id => {

                    const footer = props.footers.entries[id];
                    const lang = footer.fields.lang;

                    if (footer.fields && lang === language) {
                        return (
                            <div className="row col-xs-12" styleName="footer-container" key={id}>
                                <div className="col-xs-12 col-lg-6 col-xl-3">
                                    <h4>{footer.fields.header1}</h4>
                                    <Markdown styleName="sec-1-text">{footer.fields.section1text}</Markdown>
                                    <Markdown styleName="sec-1-copyright">{footer.fields.copyright}</Markdown>
                                    <ul>
                                        {
                                            maybeLanguageOptions ?
                                                language === 'fi'
                                                    ? <li styleName="sec-2-link" className="line-animation" onClick={switchLanguage}>Change site language to English</li>
                                                    : <li styleName="sec-2-link" className="line-animation" onClick={switchLanguage}>Vahda sivuston kieli suomeksi</li>
                                                : null
                                        }
                                        {
                                            footer.fields.commonLinks.map((entry, index) => {
                                                if (entry.includes('https://')) {
                                                    let name = parseURL(entry);
                                                    let path = name.path;
                                                    let prettyPath = "";

                                                    if (path.indexOf('-') != 0) {
                                                        prettyPath = urlPathToPrettyText(path);
                                                        return (
                                                            <li key={entry}>
                                                                <Link
                                                                    styleName="sec-2-link"
                                                                    className="line-animation"
                                                                    key={index}
                                                                    to={'/' + name.path }>
                                                                    {prettyPath}
                                                                </Link>
                                                            </li>
                                                        );
                                                    } else {
                                                        return (
                                                            <li key={entry}>
                                                                <Link
                                                                    styleName="sec-2-link"
                                                                    className="line-animation"
                                                                    key={index}
                                                                    to={'/' + name.path }>
                                                                    {name.path}
                                                                </Link>
                                                            </li>
                                                        );
                                                    }
                                                }
                                        })
                                        }
                                    </ul>
                                </div>
                                <div className="col-xs-12 col-lg-6 col-xl-3">
                                    <h4>{footer.fields.header2}</h4>
                                    <ul>
                                    {
                                        footer.fields.pick.map((entry, index) => {
                                            // Its post
                                            if (entry.fields.mainText) {

                                                const post = entry.fields;
                                                const slug = post.slug;
                                                const date = moment(post.releasedDate).format("DD-MM-YYYY");

                                                const subPathCount = window.location.pathname.split('/').length;
                                                let subPathString = '';
                                                for (let i = 0; i < subPathCount - 1; i++) {
                                                    subPathString += '../';
                                                }
                                                const toRoute = language === 'en' ? `${subPathString}blog/${date}/${slug}` : `${subPathString}blogi/${date}/${slug}`;

                                                return (
                                                    <li key={index}>
                                                        <Link
                                                            styleName="sec-2-link"
                                                            className="line-animation"
                                                            key={index}
                                                            to={toRoute} >
                                                            {post.header}
                                                        </Link>
                                                    </li>
                                                );
                                            }
                                        })
                                    }
                                    </ul>
                                </div>
                                <div className="col-xs-12 col-lg-6 col-xl-3">
                                    <h4>{footer.fields.header3}</h4>
                                    <ul>
                                        {
                                            footer.fields.pick.map((entry) => {
                                                // Its author
                                                if (entry.fields.name) {
                                                    let author = entry.fields;
                                                    // Lets use author social list
                                                    return (
                                                        author.social.map((entry, index) => {
                                                            if (entry.includes('https://')) {
                                                                let name = parseURL(entry);
                                                                name = name.host;
                                                                name = name.charAt(0).toUpperCase() + name.slice(1);
                                                                return (
                                                                    <li key={index}>
                                                                        <a
                                                                            styleName="sec-3-link"
                                                                            className="line-animation"
                                                                            key={index}
                                                                            rel="noopener noreferrer"
                                                                            target="_blank"
                                                                            href={entry}>
                                                                            {name}
                                                                        </a>
                                                                    </li>
                                                                );
                                                            }
                                                        })
                                                    );
                                                }

                                            })
                                        }
                                    </ul>
                                </div>
                                <div className="col-xs-12 col-lg-6 col-xl-3">
                                    <h4>{footer.fields.header4}</h4>
                                    <ul>
                                        {
                                            footer.fields.pick.map((entry, index) => {
                                                // Its reference
                                                if (entry.fields.monthOfDevelopment) {

                                                    const reference = entry.fields;
                                                    const slug = reference.slug;

                                                    const subPathCount = window.location.pathname.split('/').length;
                                                    let subPathString = '';
                                                    for (let i = 0; i < subPathCount - 1; i++) {
                                                        subPathString += '../';
                                                    }
                                                    const toRoute = language === 'en' ? `${subPathString}portfolio-item/${slug}` : `${subPathString}portfolio/${slug}`;

                                                    return (
                                                        <li key={index}>
                                                            <Link
                                                                styleName="sec-4-link"
                                                                className="line-animation"
                                                                key={index}
                                                                to={toRoute} >
                                                                {reference.header}
                                                            </Link>
                                                        </li>
                                                    );
                                                }
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        );
                    }
                })
            }
        </footer>
    );
}

MainFooter.propTypes = {
    app: PropTypes.object,
    footers: PropTypes.object,
    loadFooter: PropTypes.func,
    loadFooters: PropTypes.func,
    switchLanguage: PropTypes.func,
    language: PropTypes.object,
    renderLanguageOptions: PropTypes.bool
};

export default connectComponent(CSSModules(MainFooter, styles))
