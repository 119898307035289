import { makeReducer } from './util'

export const language = makeReducer(function (action) {
    switch (action.type) {
        case 'SWITCH_LANGUAGE_PENDING':
            return { fetching: true };
        case 'SWITCH_LANGUAGE_FULFILLED':
            return {
                fetching: false,
                language: action.payload.l,
                entries: action.payload.p.reduce((collection, entry) => {
                    collection[ entry.sys.id ] = entry;
                    return collection
                }, {})
            }

        case 'SWITCH_LANGUAGE_REJECTED':
            return { error: true, fetching: false }

        case 'INIT_LANGUAGE_PENDING':
            return { fetching: true };

        case 'INIT_LANGUAGE_FULFILLED':
            return {
                fetching: false,
                language: action.payload.l,
                entries: action.payload.p.reduce((collection, entry) => {
                    collection[ entry.sys.id ] = entry;
                    return collection
                }, {})
            }
        case 'INIT_LANGUAGE_REJECTED':
            return { error: true, fetching: false }
    }
}, { entries: [] });

