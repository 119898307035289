import cache from './cache';
import { postTypeId } from '../../contentful-config'

export function loadPosts (contentTypeId) {
  return cache.getCachedEntries({
      content_type: contentTypeId,
      order: '-fields.releasedDate'
  }).then(payload => {
      return payload.items;
  });
}

export function loadPost (id) {
  return cache.getCachedEntries({
      'sys.id': id
  }).then(payload => {
      return payload.items[0];
  });
}

export function loadPostWithSlug (slug) {
  return cache.getCachedEntries({
      content_type: postTypeId,
      fields: {
        slug: slug
      }
  }).then(payload => {
      return payload.items[0];
  });
}
